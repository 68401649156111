/* eslint-disable arrow-body-style */
import { Paper, Typography } from '@mui/material'
import React from 'react'

const Unauthorized = () => {
  return (
    <Paper elevation={3} sx={{ p: 10 }}>
      <Typography variant='h6'>
        Sorry! you don't have access to this page.
      </Typography>
    </Paper>
  )
}

export default Unauthorized