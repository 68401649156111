import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const NewsVerifiedAction = ({ isPositiveAction = true, modelId }) => {
  const axiosPrivate = useAxiosPrivate()
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState(isPositiveAction)

  useEffect(() => {
    setAction(isPositiveAction)
  }, [isPositiveAction])

  const activateNews = async (modelId) => {
    try {
      setLoading(true)
      const response = await axiosPrivate.patch('/articles/verified', { modelId })
      setAction((prevAction) => !prevAction)
      setLoading(false)
      console.log(response)
    } catch (error) {
      // setErrorMsg(handleAPIError(error))
    }
  }
  const inActivateNews = async (modelId) => {
    try {
      setLoading(true)
      const response = await axiosPrivate.patch('/articles/unverified', { modelId })
      setLoading(false)
      setAction((prevAction) => !prevAction)
      console.log(response)
    } catch (error) {
      // setErrorMsg(handleAPIError(error))
    }
  }
  return (
    <>
      {action ? (
        <LoadingButton loading={loading} variant="text" onClick={() => inActivateNews(modelId)}>
          {!loading && <CheckIcon color="success" />}
        </LoadingButton>
      ) : (
        <LoadingButton loading={loading} variant="text" onClick={() => activateNews(modelId)}>
          {!loading && <ClearIcon color="warning" />}
        </LoadingButton>
      )}
    </>
  )
}
NewsVerifiedAction.propTypes = {
  modelId: PropTypes.number.isRequired,
  isPositiveAction: PropTypes.bool.isRequired,
}
export default NewsVerifiedAction
