import { Checkbox, FormControlLabel } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

const FormCheckbox = ({ control, defaultValue = false, name, rules = {}, label }) => {
  const [checked, setChecked] = useState(defaultValue)

  useEffect(() => {
    setChecked(defaultValue)
  }, [defaultValue])

  return (
    <Controller
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} />}
          label={label}
          {...field}
        />
      )}
      name={name}
      defaultValue={checked}
      rules={rules}
    />
  )
}
FormCheckbox.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  control: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  errors: PropTypes.object,
  // error: PropTypes.bool
  // color: PropTypes.string,
  // size: PropTypes.string,
  // outline: PropTypes.bool,
}
export default FormCheckbox
