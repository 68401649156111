/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react-hooks/exhaustive-deps */
import { filter } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// material
// import CheckIcon from '@mui/icons-material/Check'
// import ClearIcon from '@mui/icons-material/Clear'
import {
  Alert,
  Avatar,
  Button,
  ButtonGroup,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
// components
import { sentenceCase } from 'change-case'
// import handleAPIError from '../utils/handleAPIError'
import { AVATARS_BASE_URL } from '../constants/AppConfig'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user'
import Iconify from './Iconify'
import Page from './Page'
import Scrollbar from './Scrollbar'
import SearchNotFound from './SearchNotFound'
import UserRoleAction from './actions/UserRoleAction'
import UserStatusAction from './actions/UserStatusAction'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(users, comparator, query) {
  const stabilizedThis = users.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    // return filter(users, (role) => role.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    return filter(users, (role) => role.createdAt)
  }
  return stabilizedThis.map((el) => el[0])
}

const TABLE_HEAD = [
  { id: 'avatar', label: 'Avatar', alignRight: false },
  { id: 'fullName', label: 'Name', alignRight: false },
  { id: 'isActive', label: 'Active?', alignRight: false },
  { id: 'reader', label: 'Reader?', alignRight: false },
  { id: 'author', label: 'Author?', alignRight: false },
  { id: 'article_approver', label: 'Approver?', alignRight: false },
  { id: 'admin', label: 'Admin?', alignRight: false },
]

export default function ManageUserRoles() {
  const axiosPrivate = useAxiosPrivate()
  const [errorMsg, setErrorMsg] = useState('')
  const errorMsgRef = useRef()

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('fullName')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [users, setUsers] = useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    axiosPrivate.get('/users').then((data) => {
      setErrorMsg('')
      setUsers(data.data)
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0
  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName)
  const isUserNotFound = filteredUsers.length === 0

  return (
    <Page name="Role">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <ButtonGroup variant="contained">
            <Button
              variant="contained"
              component={RouterLink}
              to="/manage-user/add"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add User
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/manage-user/manage-user-roles"
              startIcon={<Iconify icon="carbon:user-role" />}
            >
              User Roles
            </Button>
          </ButtonGroup>
        </Stack>
        {errorMsg !== '' && (
          <Stack sx={{ width: '100%' }} spacing={2} ref={errorMsgRef}>
            <Alert aria-live="assertive" severity="error">
              {errorMsg}
            </Alert>
          </Stack>
        )}

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, padding: 2 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { userId, firstName, middleName, lastName, isActive, roles } = row
                    let { avatar } = row
                    avatar =
                      // eslint-disable-next-line no-nested-ternary
                      avatar !== undefined || avatar !== null || avatar !== ''
                        ? avatar.includes('https:')
                          ? avatar
                          : `${AVATARS_BASE_URL}/${avatar}`
                        : ''

                    const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ')
                    return (
                      <TableRow hover key={userId} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={fullName} src={`${AVATARS_BASE_URL}/${avatar}`} />
                          </Stack>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            to={`/manage-user/edit/${userId}`}
                            color="inherit"
                            variant="body2"
                            component={RouterLink}
                          >
                            {sentenceCase(fullName)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <UserStatusAction isPositiveAction={isActive} userId={userId} />
                        </TableCell>
                        <TableCell align="center">
                          <UserRoleAction
                            isPositiveAction={roles?.filter((el) => el.role.name === 'READER').length > 0}
                            userId={userId}
                            name="READER"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <UserRoleAction
                            isPositiveAction={roles?.filter((el) => el.role.name === 'AUTHOR').length > 0}
                            userId={userId}
                            name="AUTHOR"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <UserRoleAction
                            isPositiveAction={roles?.filter((el) => el.role.name === 'ARTICLE_APPROVER').length > 0}
                            userId={userId}
                            name="ARTICLE_APPROVER"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <UserRoleAction
                            isPositiveAction={roles?.filter((el) => el.role.name === 'ADMIN').length > 0}
                            userId={userId}
                            name="ADMIN"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  )
}
