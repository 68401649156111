import LoadingButton from '@mui/lab/LoadingButton'
import React, { useEffect, useRef, useState } from 'react'
import Alert from '@mui/material/Alert'
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../utils/axiosConfig'
import Form from '../../components/form/Form'
import FormInput from '../../components/form/FormInput'
import FormPassword from '../../components/form/FormPassword'
import useAuth from '../../hooks/useAuth'
import handleAPIError from '../../utils/handleAPIError'

export const LoginPage = () => {
  /* DefaultValues will override the filed level defaultValue */
  /* If we wants to use form reset, then defaultValues are required */
  const defaultValues = {}
  const { control, handleSubmit, formState } = useForm({ defaultValues })
  const [errorMsg, setErrorMsg] = useState('')
  const errorMsgRef = useRef()
  const { setAuth, persist, setPersist } = useAuth()

  const togglePersist = () => {
    setPersist((prev) => !prev)
  }

  useEffect(() => {
    localStorage.setItem('persist', persist)
  }, [persist])

  /* Capturing current URL of user(in from variable), after login we will redirect user to from address  */
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const onSubmit = async ({ email, password }) => {
    try {
      const response = await axios.post('users/login', { email, password })
      setAuth({ ...response?.data, isLoggedIn: true })
      navigate(from, { replace: true })
    } catch (err) {
      setErrorMsg(handleAPIError(err))
      errorMsgRef.current.focus()
    }
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        // errors={formState.errors}
        defaultValues={defaultValues}
      >
        <Stack spacing={3}>
          {/* <span> {JSON.stringify(formState.errors)}</span> */}
          <FormInput
            fullWidth
            name="email"
            placeholder="Email"
            rules={{
              required: {
                value: true,
                message: 'This is required field..',
              },
            }}
            control={control}
            defaultValue=""
            errors={formState.errors}
            autoFocus
          />

          <FormPassword
            name="password"
            placeholder="Password"
            rules={{
              required: {
                value: true,
                message: 'This is required field..',
              },
            }}
            control={control}
            defaultValue=""
            errors={formState.errors}
            showPassword
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={persist} onChange={togglePersist} inputProps={{ 'aria-label': 'remember Me' }} />
              }
              label="Remember Me"
            />
          </FormGroup>

          {errorMsg !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2} ref={errorMsgRef}>
              <Alert aria-live="assertive" severity="error">
                {errorMsg}
              </Alert>
            </Stack>
          )}
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={formState.isSubmitting}>
            Login
          </LoadingButton>
        </Stack>
      </Form>
    </>
  )
}
