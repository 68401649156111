import { useContext, useDebugValue } from "react";
import AuthContext from "../context/AuthProvider";


// Using this hook, we don't require to always call the context to get the auth information
// basically it is reducing the code and extracting it in this separate file
const useAuth = () => {
    const { auth } = useContext(AuthContext);
    // The second param is a anonymous function
    // If we pass it like this: auth => auth?.user ? "Logged In" : "Logged Out"
    // It will perform the operation only if the react-dev-tool is open
    // We should only use it we are going to publish it as an npm package
    useDebugValue(auth, auth => auth?.user ? "Logged In" : "Logged Out")
    return useContext(AuthContext);
}

export default useAuth;