import { LoadingButton } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

const UserRoleAction = ({ isPositiveAction = true, userId, name }) => {
  const axiosPrivate = useAxiosPrivate()
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState(isPositiveAction)

  useEffect(() => {
    setAction(isPositiveAction)
  }, [isPositiveAction])

  const mapRole = async (userId, name) => {
    try {
      setLoading(true)
      const response = await axiosPrivate.post('/roles/map-role', { userId, name })
      setAction((prevAction) => !prevAction)
      setLoading(false)
      console.log(response)
    } catch (error) {
      // setErrorMsg(handleAPIError(error))
    }
  }
  const unMapRole = async (userId, name) => {
    try {
      setLoading(true)
      const response = await axiosPrivate.post('/roles/unmap-role', { userId, name })
      setLoading(false)
      setAction((prevAction) => !prevAction)
      console.log(response)
    } catch (error) {
      // setErrorMsg(handleAPIError(error))
    }
  }
  return (
    <>
      {action ? (
        <LoadingButton loading={loading} variant="text" onClick={() => unMapRole(userId, name)}>
          {!loading && <CheckIcon color="success" />}
        </LoadingButton>
      ) : (
        <LoadingButton loading={loading} variant="text" onClick={() => mapRole(userId, name)}>
          {!loading && <ClearIcon color="warning" />}
        </LoadingButton>
      )}
    </>
  )
}
UserRoleAction.propTypes = {
  userId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isPositiveAction: PropTypes.bool.isRequired,
}
export default UserRoleAction
