import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import Editor from 'ckeditor5-custom-build/build/ckeditor'
import MyUploadAdapter from './MyUploadAdapter'

const FormTextAreaCK = ({
  name,
  rules,
  placeholder,
  control,
  errors = {},
  label,
  // outline = false,
  defaultValue = '',
  onFocus,
  // onChange,
}) => {
  // const error = errors?.[name]?.message === ""
  const helperText = errors?.[name]?.message
  const error = helperText !== undefined

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
      // Configure the URL to the upload script in your back-end here!
      new MyUploadAdapter(loader)
  }

  return (
    <Controller
      render={({ field }) => (
        <CKEditor
          config={{
            extraPlugins: [MyCustomUploadAdapterPlugin],
          }}
          image={{
            resizeOptions: [
              {
                name: 'resizeImage:original',
                value: null,
                icon: 'original',
              },
              {
                name: 'resizeImage:50',
                value: '50',
                icon: 'medium',
              },
              {
                name: 'resizeImage:75',
                value: '75',
                icon: 'large',
              },
            ],
            toolbar: ['resizeImage'],
          }}
          editor={ClassicEditor}
          data={defaultValue}
          error={error}
          helperText={helperText}
          margin="normal"
          fullWidth
          id={name}
          label={label}
          placeholder={placeholder}
          onFocus={onFocus}
          // onChange={onChange}
          {...field}
        />
      )}
      name={name}
      control={control}
      rules={rules}
    />
  )
}

FormTextAreaCK.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.any,
  helperText: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  onFocus: PropTypes.func,
  // onChange: PropTypes.func,
  // color: PropTypes.string,
  // size: PropTypes.string,
  // outline: PropTypes.bool,
}

export default FormTextAreaCK
