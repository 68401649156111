/* eslint-disable no-nested-ternary */
import { useContext, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material'
// components
import { deepPurple } from '@mui/material/colors'
import AuthContext from '../../context/AuthProvider'
import { AVATARS_BASE_URL } from '../../constants/AppConfig'
import MenuPopover from '../../components/MenuPopover'
// mocks_
import useLogout from '../../hooks/useLogout'

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  /* {
    label: 'Profile',
    icon: 'eva:person-fill',
    linkTo: '#',
  }, */
  /* {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
    linkTo: '#',
  }, */
]

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(null)
  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }
  const { auth } = useContext(AuthContext)
  const logout = useLogout()
  const handleLogout = () => {
    setOpen(null)
    logout()
  }
  const handleClose = () => {
    setOpen(null)
    // MediaMeetra: In case of closing of the popup, we don't want to logout
    // logout()
  }
  // console.log("Auth: ", JSON.stringify(auth))
  // console.log("Auth: ", auth?.avatar)
  const avatar =
    auth?.avatar !== undefined || auth.avatar !== null || auth.avatar !== ''
      ? auth.avatar.includes('https:')
        ? auth?.avatar
        : `${AVATARS_BASE_URL}/${auth?.avatar}`
      : ''

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {auth?.avatar !== null ? (
          <Avatar src={avatar} alt={auth.firstName} />
        ) : (
          <Avatar sx={{ bgcolor: deepPurple[500] }} alt={auth.firstName} />
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {[auth.firstName, auth.middleName, auth.lastName].filter(Boolean).join(' ')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {auth.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  )
}
