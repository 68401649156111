/* eslint-disable no-nested-ternary */
import { Avatar, Box, Drawer, Link, Typography } from '@mui/material'
// material
import { styled } from '@mui/material/styles'
import { sentenceCase } from 'change-case'
import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// components
import Logo from '../../components/Logo'
import NavSection from '../../components/NavSection'
import Scrollbar from '../../components/Scrollbar'
import { AVATARS_BASE_URL } from '../../constants/AppConfig'
import AuthContext from '../../context/AuthProvider'
// hooks
import useResponsive from '../../hooks/useResponsive'
//
import { adminNavConfig, guestNavConfig, authorNavConfig, articleApproverNavConfig, readerNavConfig } from './NavConfig'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}))

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}))

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation()
  const isDesktop = useResponsive('up', 'lg')

  const { auth } = useContext(AuthContext)
  const [navConfig, setNavConfig] = useState([])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (auth.isLoggedIn) {
      const roles = auth?.roles?.map((el) => el.role.name)
      const navCnf = []
      if (roles.includes('ADMIN')) {
        navCnf.push(...adminNavConfig)
      }
      if (roles.includes('AUTHOR')) {
        navCnf.push(...authorNavConfig)
      }
      if (roles.includes('ARTICLE_APPROVER')) {
        navCnf.push(...articleApproverNavConfig)
      }
      if (roles.includes('READER')) {
        navCnf.push(...readerNavConfig)
      }
      // https://stackoverflow.com/a/58437069/3110474
      // v,i,a == value, index, array
      setNavConfig(navCnf.filter((v, i, a) => a.findIndex((v2) => v.title === v2.title && v.path === v2.path) === i))
    } else {
      setNavConfig(guestNavConfig)
    }
  }, [auth.isLoggedIn, auth?.roles])
  let avatar = ''
  if (auth.avatar !== undefined) {
    // avatar = auth.avatar.includes('https:') ? auth?.avatar : `${AVATARS_BASE_URL}/${auth?.avatar}`
    avatar =
      auth?.avatar !== undefined || auth.avatar !== null || auth.avatar !== ''
        ? auth.avatar.includes('https:')
          ? auth?.avatar
          : `${AVATARS_BASE_URL}/${auth?.avatar}`
        : ''
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      {auth.isLoggedIn && (
        <Box sx={{ mb: 5, mx: 2.5 }}>
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
              <Avatar src={avatar} alt={auth.firstName} />
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {`${sentenceCase(auth.firstName)} ${!auth.middleName ? '' : sentenceCase(auth.middleName)} ${
                    !auth.lastName ? '' : sentenceCase(auth.lastName)
                  }`}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {auth?.roles?.map((el) => sentenceCase(el.role.name)).join(', ')}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box>
      )}

      {/* {auth.isLoggedIn === true ?
        <NavSection navConfig={adminNavConfig} />
        : <NavSection navConfig={guestNavConfig} />
      } */}
      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
      <Link target="_blank" href="tel:+919414633963" underline="hover">
        <img src="/images/advertisements/advertisement-1.png" alt="Media Meetra" />
      </Link>

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  )

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
