export const NAME = 'Media Meetra!'

export const WEB_URL = process.env.NODE_ENV === 'production' ? 'https://www.mediameetra.com' : 'http://localhost:3000'

export const API_BASE_URL =
  process.env.NODE_ENV === 'production' ? 'https://api.mediameetra.com/api' : 'http://localhost:5000/api'

export const IMAGES_BASE_URL =
  process.env.NODE_ENV === 'production' ? 'https://api.mediameetra.com/public' : 'http://localhost:5000/public'

export const ARTICLES_IMAGES_BASE_URL_FOR_SHARE =
  process.env.NODE_ENV === 'production'
    ? 'https://api.mediameetra.com/public/articles/1125x600'
    : 'http://localhost:5000/public/articles/1125x600'

/* The REST endpoint to upload image for avatars */
export const AVATARS_BASE_URL = `${IMAGES_BASE_URL}/avatars`

/* The REST endpoint to upload image for articles */
// TODO: Image resizing is not working properly in case of multiple images are uploading, it is overriding the image
// export const ARTICLES_BASE_IMG_URL = `${IMAGES_BASE_URL}/articles/1200xauto`
export const ARTICLES_BASE_IMG_URL = `${IMAGES_BASE_URL}/articles/original`

/* The REST endpoint to upload image from CKEditor */
export const ARTICLES_CONTENT_IMAGE_BASE_URL = `${IMAGES_BASE_URL}/article-contents`

/* The base location from where the images can be fetched */
export const ARTICLES_CONTENT_IMAGE_UPLOAD_URL = `${API_BASE_URL}/articles/news-content-images`
