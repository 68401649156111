/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom'
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-boolean-value */
import LoadingButton from '@mui/lab/LoadingButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
// import Checkbox from '@mui/material/Checkbox'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import FormGroup from '@mui/material/FormGroup'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import FormTextArea from '../components/form/FormTextArea'
import Form from '../components/form/Form'
import FormFileUpload from '../components/form/FormFileUpload'
import FormInput from '../components/form/FormInput'
import FormTextAreaCK from '../components/form/FormTextAreaCK'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import axios from '../utils/axiosConfig'
import { ARTICLES_BASE_IMG_URL } from '../constants/AppConfig'
import handleAPIError from '../utils/handleAPIError'

const EditNews = () => {
  /* DefaultValues will override the filed level defaultValue */
  /* If we wants to use form reset, then defaultValues are required */
  const { persist } = useAuth()
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const { articleSlug } = useParams()
  const [formAction, setFormAction] = useState('ADD')
  const axiosPrivate = useAxiosPrivate()
  const [ckEditor, setCKEditor] = useState(null)

  const deleteImage = async (image, modelId, isBanner) => {
    const response = await axiosPrivate.patch('/articles/delete-image', { image, modelId, isBanner })
    console.log(`res: ${JSON.stringify(response)}`)
  }

  // console.log(`articleSlug :${articleSlug}`)
  const errorMsgRef = useRef()
  const successMsgRef = useRef()

  // const defaultValues = {}
  const [defaultValues, setDefaultValues] = useState({})
  const { control, handleSubmit, formState, reset, register, setValue } = useForm({ defaultValues })
  // const [editor, setEditor] = useState(null)
  const handleOnFocus = (event, editor) => {
    /* Because onChange event is not working for CKEditor */
    setCKEditor(() => editor)
    // console.log('Focus.', editor.getData())
    setValue('content', editor.getData())
  }
  /* onChange event is not fired from CKEditor due to some unknown issue */
  /* const handleOnChange = (event, editor) => {
    console.log(editor.getData())
    setValue('content', editor.getData())
  } */
  useEffect(() => {
    setErrorMsg('')
    if (articleSlug !== undefined) {
      setFormAction('UPDATE')
      axios.get(`/articles/${articleSlug}`).then((data) => {
        reset(data.data)
        setDefaultValues(data.data)
        setValue('content', data.data.content)
      })
    } else {
      /* 
        If action is Add, then initialize content with empty string
      */
      setValue('content', '')
    }
    return () => {
      // console.log("Returning")
    }
  }, [])
  // console.log(`defaultValues: ${JSON.stringify(defaultValues)}`)

  useEffect(() => {
    localStorage.setItem('persist', persist)
  }, [persist])

  /* Capturing current URL of user(in from variable), after login we will redirect user to from address  */
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.pathname || '/'
  console.log(`It came from: ${from}`)

  const onSubmit = async (data) => {
    setErrorMsg('')
    try {
      const formData = new FormData()
      if (data.articleImages) {
        for (let i = 0; i < data.articleImages.length; i++) {
          formData.append(`articleImages`, data.articleImages[i])
        }
      }
      if (data.articleImage) {
        formData.append(`articleImage`, data.articleImage[0])
      }
      formData.append(`title`, data.title)
      formData.append(`content`, data.content)
      console.log(`data.content: ${data.content}`)
      /* 
        If content is not captured from onFocus, trying to get it from CHEditor Object
        Which was also initialized in onFocus event handler only
      */
      try {
        console.log(`ckEditor.getData(): ${ckEditor.getData()}`)
        setValue('content', ckEditor.getData())
      } catch (error) {
        console.error('Error while getting ckeditor data')
      }
      if (data.content === '[object Object]' || data.content === '<p>[object Object]</p>' || data.content === 'p') {
        setErrorMsg('Unable to capture News Content, click on editor once please..')
        return
      }
      // formData.append(`content`, ckEditor.getData())
      // console.log('CKEditor Data: ')
      // console.log(ckEditor.getData())
      formData.append(`contentSummary`, data.contentSummary)
      formData.append(`slug`, data.slug)
      let response
      if (formAction === 'ADD') {
        response = await axiosPrivate.post('articles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
      if (formAction === 'UPDATE') {
        formData.append(`articleId`, defaultValues.articleId)
        response = await axiosPrivate.put('articles', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }

      if (response.status === 201) {
        setSuccessMsg('Submitted successfully, submit another?')
        reset()
        successMsgRef.current.focus()
        // navigate(from, { replace: true })
        navigate(-1)
      }
    } catch (err) {
      setErrorMsg(handleAPIError(err))
      errorMsgRef.current.focus()
    }
  }
  return (
    <Paper
      elevation={3}
      sx={{
        padding: { xs: 1, md: 5 },
        margin: { xs: 1, md: 5 },
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" align="center" color="initial" component={RouterLink} to="/manage-news/add">
          {articleSlug === undefined ? 'Add News' : 'Edit News'}
        </Typography>
        <Button variant="text" size="large" onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon /> Go Back
        </Button>
      </Stack>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        defaultValues={defaultValues}
        register={register}
        // errors={formState.errors}
      >
        <Stack spacing={3}>
          {/* <span> {JSON.stringify(formState.errors)}</span> */}
          <FormInput
            fullWidth
            name="title"
            placeholder="Title"
            rules={{
              required: {
                value: true,
                message: 'This is required field..',
              },
              minLength: {
                value: 30,
                message: 'Less than 30 words',
              },
              maxLength: {
                value: 100,
                message: 'More than 100 words',
              },
            }}
            control={control}
            errors={formState.errors}
            autoFocus
            helperText="Title"
          />
          <FormInput
            fullWidth
            name="slug"
            placeholder="slug is the page URL after base domain"
            control={control}
            errors={formState.errors}
          />
          <FormTextArea
            fullWidth
            name="contentSummary"
            placeholder="Content Summary should be in 120 to 170 words"
            rules={{
              required: {
                value: true,
                message: 'This is required field..',
              },
              minLength: {
                value: 120,
                message: 'It cannot be less than 120 characters',
              },
              maxLength: {
                value: 170,
                message: 'It cannot be more than 170 characters',
              },
            }}
            control={control}
            errors={formState.errors}
          />

          <Typography variant="overline">Images for slider/carousel</Typography>
          <FormFileUpload
            name="articleImages"
            control={control}
            multiple={true}
            defaultImages={defaultValues.images}
            baseURL={ARTICLES_BASE_IMG_URL}
            modelId={defaultValues.articleId}
            deleteCB={deleteImage}
          />

          <Typography variant="overline">Image for Poster/Thumbnail</Typography>
          <FormFileUpload
            name="articleImage"
            control={control}
            multiple={false}
            defaultImage={defaultValues.image}
            baseURL={ARTICLES_BASE_IMG_URL}
            modelId={defaultValues.articleId}
            deleteCB={deleteImage}
          />
          <Paper
            sx={{
              pt: 5,
              mt: 5,
            }}
          >
            <FormTextAreaCK
              errors={formState.errors}
              name="content"
              rules={{
                required: {
                  value: true,
                  message: 'This is required field..',
                },
              }}
              placeholder="Enter the NEWS here.."
              control={control}
              label="NEWS Content"
              defaultValue={defaultValues.content}
              data={defaultValues.content}
              onReady={(editor) => {
                console.log('Editor is ready to use!', editor)
              }}
              onFocus={handleOnFocus}
            />
          </Paper>

          {/* <p ref={errorMsgRef} className={errorMsg ? "errmsg" : "offscreen"} >{errorMsg}</p> */}
          {errorMsg !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert ref={errorMsgRef} aria-live="assertive" severity="error">
                {errorMsg}
              </Alert>
            </Stack>
          )}
          {successMsg !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert ref={successMsgRef} aria-live="assertive" severity="success">
                {successMsg}
              </Alert>
            </Stack>
          )}
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={formState.isSubmitting}>
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </Paper>
  )
}

export default EditNews
