import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Avatar, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const FormFileUpload = ({
  name,
  rules = {},
  control,
  multiple = false,
  defaultImages = [],
  defaultImage = '',
  modelId,
  deleteCB,
  baseURL = '',

  // helperText = "",
  // error = false,
  // helperText,
  // color = "lightBlue",
  // size = "regular",
  // outline = false,
}) => {
  // const helperText = errors?.[name]?.message
  // const error = helperText !== undefined
  const [imgs, setImgs] = useState()
  // console.log(defaultImages)
  // console.log(`error: ${error}`)

  return (
    // https://stackoverflow.com/a/68336257/3110474
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <input
            type="file"
            onChange={(e) => {
              field.onChange(e.target.files)
              setImgs(e.target.files)
            }}
            multiple={multiple}
          />
        )}
      />
      {/* Preview of selected files to be uploaded */}
      {imgs && (
        <Stack direction="row" spacing={1}>
          {[...imgs].map((file, i) => (
            <Item key={i}>
              <Avatar alt="" src={URL.createObjectURL(file)} variant="square" sx={{ width: 56, height: 56 }} />
            </Item>
          ))}
        </Stack>
      )}
      {/* Preview of already uploaded files */}
      {multiple === true && (
        <Stack direction="row" spacing={1}>
          {defaultImages.map((img, index) => (
            <Item key={index}>
              <Avatar alt="img" src={`${baseURL}/${img}`} variant="square" sx={{ width: 56, height: 56 }} />
              <Tooltip title="Delete">
                <IconButton aria-label="delete" size="small" onClick={() => deleteCB(img, modelId, !multiple)}>
                  <DeleteOutlineIcon fontSize="inherit" color="error" />
                </IconButton>
              </Tooltip>
            </Item>
          ))}
        </Stack>
      )}
      {/* Preview of already uploaded file */}
      {!multiple && defaultImage !== '' && (
        <Stack direction="row" spacing={1}>
          <Item>
            <Avatar alt="" src={`${baseURL}/${defaultImage}`} variant="square" sx={{ width: 56, height: 56 }} />
            <Tooltip title="Delete">
              <IconButton aria-label="delete" size="small" onClick={() => deleteCB(defaultImage, modelId, !multiple)}>
                <DeleteOutlineIcon fontSize="inherit" color="error" />
              </IconButton>
            </Tooltip>
          </Item>
        </Stack>
      )}
    </>
  )
}

FormFileUpload.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  name: PropTypes.string,
  rules: PropTypes.object,
  control: PropTypes.any,
  multiple: PropTypes.bool,
  defaultImages: PropTypes.array,
  defaultImage: PropTypes.string,
  modelId: PropTypes.any,
  baseURL: PropTypes.any,
  deleteCB: PropTypes.func,
  // error: PropTypes.bool
  // color: PropTypes.string,
  // size: PropTypes.string,
  // outline: PropTypes.bool,
}

export default FormFileUpload
