// component
import Iconify from '../../components/Iconify'

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />

export const adminNavConfig = [
  /* {
    title: 'dashboard',
    path: '/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  }, */
  {
    title: 'Manage NEWS',
    path: '/manage-news',
    icon: getIcon('entypo:news'),
  },
  {
    title: 'Manage Users',
    path: '/manage-user',
    icon: getIcon('bxs:user-account'),
  },
  {
    title: 'Manage Categories',
    path: '/manage-categories',
    icon: getIcon('carbon:category-new-each'),
  },
  {
    title: 'Manage Roles',
    path: '/manage-roles',
    icon: getIcon('eos-icons:role-binding-outlined'),
  },
  {
    title: 'Manage Tags',
    path: '/manage-tags',
    icon: getIcon('clarity:tags-outline-badged'),
  },
  {
    title: 'news',
    path: '/news',
    icon: getIcon('emojione-monotone:newspaper'),
  },
]

export const authorNavConfig = [
  {
    title: 'Manage NEWS',
    path: '/manage-news',
    icon: getIcon('entypo:news'),
  },
  {
    title: 'news',
    path: '/news',
    icon: getIcon('emojione-monotone:newspaper'),
  },
]

export const articleApproverNavConfig = [
  {
    title: 'Manage NEWS',
    path: '/manage-news',
    icon: getIcon('entypo:news'),
  },
  {
    title: 'news',
    path: '/news',
    icon: getIcon('emojione-monotone:newspaper'),
  },
]

export const guestNavConfig = [
  {
    title: 'login',
    path: '/login',
    icon: getIcon('majesticons:login-line'),
  },
  /* {
    title: 'register',
    path: '/register',
    icon: getIcon('ant-design:user-add-outlined'),
  }, */
  /* {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill'),
  }, */
  {
    title: 'news',
    path: '/news',
    icon: getIcon('emojione-monotone:newspaper'),
  },
  /* {
    title: 'unauthorized',
    path: '/unauthorized',
    icon: getIcon('eva:file-text-fill'),
  } */
]

export const readerNavConfig = [
  {
    title: 'news',
    path: '/news',
    icon: getIcon('emojione-monotone:newspaper'),
  },
]
