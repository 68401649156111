import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

const FormTextArea = ({
  name,
  rules,
  placeholder,
  control,
  errors = {},
  label,
  // outline = false,
  defaultValue = '',
}) => {
  // const error = errors?.[name]?.message === ""
  const helperText = errors?.[name]?.message
  const error = helperText !== undefined
  // console.log(`Error: ${error} and message: ${helperText}`)

  return (
    <Controller
      render={({ field }) => (
        <TextField
          error={error}
          helperText={helperText}
          margin="normal"
          fullWidth
          id={name}
          label={label}
          placeholder={placeholder}
          multiline
          maxRows={8}
          minRows={3}
          {...field}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
    />
  )
}

FormTextArea.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.any,
  helperText: PropTypes.string,
  errors: PropTypes.object,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  // color: PropTypes.string,
  // size: PropTypes.string,
  // outline: PropTypes.bool,
}

export default FormTextArea
