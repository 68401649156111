/* eslint-disable react-hooks/exhaustive-deps */
import { filter } from 'lodash'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// material
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import {
  Avatar,
  Button,
  Card,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
// components
import NewsActiveAction from '../components/actions/NewsActiveAction'
import NewsPublishAction from '../components/actions/NewsPublishAction'
import NewsVerifiedAction from '../components/actions/NewsVerifiedAction'
import Iconify from '../components/Iconify'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import { ARTICLES_BASE_IMG_URL } from '../constants/AppConfig'
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { fDate } from '../utils/formatTime'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(articles, comparator, query) {
  const stabilizedThis = articles.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    // return filter(articles, (article) => article.title.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    return filter(articles, (article) => article.createdAt)
  }
  return stabilizedThis.map((el) => el[0])
}

const TABLE_HEAD = [
  { id: 'image', label: 'Image', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'Author', label: 'Author', alignRight: false },
  { id: 'isActive', label: 'Active?', alignRight: false },
  { id: 'isVerified', label: 'Verified?', alignRight: false },
  { id: 'isPublished', label: 'Published?', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
]

export default function ManageNEWS() {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('desc')
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState('createdAt')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const axiosPrivate = useAxiosPrivate()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = articles.map((n) => n.title)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const [articles, setArticles] = useState([])
  useEffect(() => {
    axiosPrivate.get('/articles/all?ipp=500&page=1&sortBy=createdAt&order=asc').then((data) => {
      setArticles(data.data.data)
    })
  }, [])

  /* const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  }; */

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - articles.length) : 0

  const filteredUsers = applySortFilter(articles, getComparator(order, orderBy), filterName)

  const isUserNotFound = filteredUsers.length === 0

  return (
    <Page title="News">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            NEWS
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/manage-news/add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add News
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, padding: 2 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={articles.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { articleId, title, user, createdAt, isVerified, isActive, isPublished, image, slug } = row
                    // const isItemSelected = selected.indexOf(title) !== -1

                    return (
                      <TableRow hover key={articleId} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          {/* <Stack direction="row" alignItems="center" spacing={2}> */}
                          <Avatar alt={title} src={`${ARTICLES_BASE_IMG_URL}/${image}`} />
                          {/* <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography> */}
                          {/* </Stack> */}
                        </TableCell>
                        <TableCell align="left" width={300}>
                          <Typography to={`/news/${slug}`} color="inherit" variant="body2" component={RouterLink}>
                            {title}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{fDate(createdAt)}</TableCell>
                        <TableCell align="left">{user.firstName}</TableCell>
                        <TableCell align="left">
                          <NewsActiveAction isPositiveAction={isActive} modelId={articleId} />
                        </TableCell>
                        <TableCell align="left">
                          <NewsVerifiedAction isPositiveAction={isVerified} modelId={articleId} />
                        </TableCell>
                        <TableCell align="left">
                          <NewsPublishAction isPositiveAction={isPublished} modelId={articleId} />
                        </TableCell>
                        <TableCell align="right">
                          {/* <UserMoreMenu /> */}
                          <IconButton aria-label="edit" component={RouterLink} to={`/manage-news/edit/${slug}`}>
                            <EditRoundedIcon color="action" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={articles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  )
}
