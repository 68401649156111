const handleAPIError = (err) => {
  if (!err?.response) {
    return `${err.response?.status} : No Response from the server `
  }

  // return `${err.response?.status} : ${err.response.data.message}`
  return `${err.response.data.message}`
  /* switch (err.response?.status) {
    case 400:
      return (`${err.response?.status} : Missing Username or Password`)
    case 401:
      return (`${err.response?.status} : Unauthorized`)
    case 500:
      return (`${err.response?.status} : Something broken on Server`)
    default:
      return (`${err.response?.status} : This status is yet to be handled`)
  } */
}

// const handleAPIError = (err) => `${err.response?.status} : ${err.response.data.message}`

export default handleAPIError
