/* eslint-disable react/no-unknown-property */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
// InstapaperShareButton,
// InstapaperIcon,
// LinkedinShareButton,
// TelegramShareButton,
// TwitterShareButton,
// WhatsappShareButton,
// WorkplaceShareButton,
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { LoadingButton } from '@mui/lab'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home'
import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import parse from 'html-react-parser'
import { ARTICLES_IMAGES_BASE_URL_FOR_SHARE, WEB_URL, ARTICLES_BASE_IMG_URL } from '../constants/AppConfig'
import axios from '../utils/axiosConfig'
import { fDate } from '../utils/formatTime'

function Item({ imgSrc }) {
  return (
    <Paper elevation={3} sx={{ height: '25%' }}>
      <img src={`${ARTICLES_BASE_IMG_URL}/${imgSrc}`} alt="Post" />
    </Paper>
  )
}

const BlogDetails = () => {
  const { slug } = useParams()
  const [post, setPost] = useState()
  // const items = useState([])
  // const [error, setError] = useState({})
  const navigate = useNavigate()
  const [schemaData, setSchemaData] = useState({})

  useEffect(() => {
    axios.get(`/articles/${slug}`).then((data) => {
      setPost(() => data.data)
      setSchemaData({
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        url: window.location.href,
        publisher: {
          '@type': 'Organization',
          name: 'Media Meetra',
          logo: `${WEB_URL}/images/logo.png`,
        },
        headline: data.data.title,
        mainEntityOfPage: window.location.href,
        articleBody: data.data.content,
        image: [data.data.image, ...data.data.images],
        datePublished: '2017-03-20T20:30:54+00:00',
        author: 'Media Meetra',
      })
      //       document.head.innerHTML += `
      //       <meta property="og:url" content=${WEB_URL}/news/${post?.slug} />
      //       <meta property="og:title" content=${post?.title} />
      //       <meta property="og:description" content=${post?.contentSummary} />
      //       <meta property="og:image" content=${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image} />
      //       <meta property="og:image:secure_url" content=${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image} />
      // `
    })
    // eslint-disable-next-line no-unused-expressions
    // document.title = post?.title
    // document.querySelector("meta[name='description']").setAttribute('content', post?.contentSummary)
    // document
    //   .querySelector("meta[property='og:image']")
    //   .setAttribute('content', `${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image}`)
    // document
    //   .querySelector("meta[property='og:image:secure_url']")
    //   .setAttribute('content', `${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image}`)
  }, [])

  return (
    <>
      {post !== undefined ? (
        <>
          <Helmet>
            <title>MediaMeetra | {post?.title}</title>
            <link rel="canonical" href={`${WEB_URL}/news/${post?.slug}`} />
            <meta name="description" content={post?.contentSummary} />
            <meta name="keywords" content="NEWS, Balotra NEWS, World NEWS, India, India NEWS" />
            {/* <!-- Twitter --> */}
            <meta property="twitter:url" content={`${WEB_URL}/news/${post?.slug}`} />
            <meta property="twitter:title" content={post?.title} />
            <meta property="twitter:description" content={post?.contentSummary} />
            <meta property="twitter:image" content={`${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image}`} />

            <meta property="og:url" content={`${WEB_URL}/news/${post?.slug}`} />
            <meta property="og:title" content={post?.title} />
            <meta property="og:description" content={post?.contentSummary} />
            <meta property="og:image" content={`${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image}`} />
            <meta property="og:image:secure_url" content={`${ARTICLES_IMAGES_BASE_URL_FOR_SHARE}/${post.image}`} />
            {/* <meta property="og:image:type" content="image/jpeg" /> */}
            <meta property="og:image:width" content="1125" />
            <meta property="og:image:height" content="600" />
            <meta property="og:image:alt" content={post?.title} />
            <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
          <Box>
            {post.images?.length > 0 ? (
              <Carousel>
                {post.images.map((imgSrc, i) => (
                  <Item key={i} imgSrc={imgSrc} />
                ))}
              </Carousel>
            ) : (
              post.image !== undefined && <img src={`${ARTICLES_BASE_IMG_URL}/${post.image}`} alt={post.title} />
            )}
          </Box>
          <Box
            sx={{
              paddingBottom: 2,
              paddingRight: 2,
              paddingLeft: 2,
            }}
          >
            <Typography variant="h3" component="h1" spacing={3} my={4} fontSize={32}>
              {post.title}
            </Typography>

            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
              <Box>
                <Typography>
                  Posted By: {post.user.firstName} on {fDate(post.createdAt)}
                </Typography>
              </Box>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={0.5}>
                <FacebookShareButton
                  size={32}
                  iconfillcolor={'blue'}
                  url={window.location.href}
                  quote={post.title}
                  hashtag="#MediaMeetra"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href} title={post.title} separator=" : ">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href} title={post.title} separator=" : ">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Stack>
            </Stack>
            <Stack direction="column" justifyContent="space-between" alignItems="stretch" spacing={1}>
              {/* <Typography variant="inherit" sx={{ paddingTop: 4, fontSize: 16 }}> */}
              <Paper sx={{ paddingTop: 4, fontSize: '1.4rem' }}>{parse(post.content)}</Paper>
              {/* </Typography> */}
              <Button variant="text" size="large" onClick={() => navigate('/')}>
                <HomeIcon /> Home
              </Button>
            </Stack>
          </Box>
        </>
      ) : (
        <LoadingButton loading variant="text" />
      )}
    </>
  )
}

export default BlogDetails
