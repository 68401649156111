import React from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'

const Form = ({
  children,
  onSubmit,
  // errors,
  register,
}) => (
  <Box component="form" onSubmit={onSubmit} noValidate>
    {Array.isArray(children)
      ? children.map((child) =>
          child?.props.name
            ? React.createElement(child?.type, {
                ...register(child?.props.name),
                ...child?.props,
                key: child?.props.name,
              })
            : child
        )
      : children}
  </Box>
)

Form.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  children: PropTypes.element,
  onSubmit: PropTypes.func,
  register: PropTypes.any,
  // errors: PropTypes.object,
}

export default Form
