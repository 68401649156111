/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useEffect, useRef, useState } from 'react'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-boolean-value */
import LoadingButton from '@mui/lab/LoadingButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { Button } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
// import Checkbox from '@mui/material/Checkbox'
// import FormControlLabel from '@mui/material/FormControlLabel'
// import FormGroup from '@mui/material/FormGroup'
import { useForm } from 'react-hook-form'
import Form from '../components/form/Form'
import FormFileUpload from '../components/form/FormFileUpload'
import FormInput from '../components/form/FormInput'
import useAuth from '../hooks/useAuth'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { AVATARS_BASE_URL } from '../constants/AppConfig'
import { fDate } from '../utils/formatTime'
import FormCheckbox from '../components/form/FormCheckbox'
import handleAPIError from '../utils/handleAPIError'

const EditUser = () => {
  /* DefaultValues will override the filed level defaultValue */
  /* If we wants to use form reset, then defaultValues are required */
  const axiosPrivate = useAxiosPrivate()
  const [errorMsg, setErrorMsg] = useState('')
  const errorMsgRef = useRef()

  const { persist } = useAuth()
  const { userId } = useParams()
  const [formAction, setFormAction] = useState('ADD')
  const [defaultValues, setDefaultValues] = useState({})
  const { control, handleSubmit, formState, reset, register } = useForm({ defaultValues })

  const deleteImage = async (avatar, modelId, isBanner = true) => {
    try {
      const response = await axiosPrivate.patch('/users/delete-avatar', { avatar, modelId, isBanner })
      console.log(`res: ${JSON.stringify(response)}`)
      console.log(response.status)
    } catch (err) {
      setErrorMsg(handleAPIError(err))
      errorMsgRef.current.focus()
    }
  }

  useEffect(() => {
    setErrorMsg('')
    if (userId !== undefined) {
      setFormAction('UPDATE')
      axiosPrivate.get(`/users/${userId}`).then((response) => {
        // console.log(response.data)
        // reset(response.data?.map(el => el === null ? el : ''))
        // const formDefaultData = response?.data?.filter(el => el !== null)
        for (const key in response.data) {
          if (response.data[key] === null) {
            response.data[key] = ''
          }
        }
        // const formDefaultData = response.data?.for(el => el === null ? el : '')
        // reset(formDefaultData)
        // setDefaultValues(formDefaultData)
        setDefaultValues(response.data)
        reset(response.data)
        // console.log(`response.data.isActive: ${response.data.isActive}`)
        // setValue(data.data)
      })
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('persist', persist)
  }, [persist])

  /* Capturing current URL of user(in from variable), after login we will redirect user to from address  */
  const navigate = useNavigate()
  // const location = useLocation()
  // const from = location.state?.from?.pathname || '/'

  const onSubmit = async (data) => {
    try {
      const formData = new FormData()

      // Adding all the form elements into formData
      for (const key in data) {
        // console.log(`${key} : ${data[key]}`)
        if (key !== 'avatar') {
          formData.append(key, data[key])
        } else {
          formData.append(key, data[key][0])
        }
      }
      console.log(`formData:`)
      formData.forEach((element) => {
        console.log(element)
      })
      // Deleting Image data
      /* formData.delete('avatar')
      if (data.avatar) {
        formData.append(`avatar`, data.avatar[0])
      } */
      // console.log(formData)
      // if (true) return

      let response
      if (formAction === 'ADD') {
        response = await axiosPrivate.post('users/add-user', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
      if (formAction === 'UPDATE') {
        // formData.append(`articleId`, defaultValues.articleId)
        response = await axiosPrivate.put('users', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
      }
      console.log(`response: ${JSON.stringify(response)}`)

      if (response.status === 201) {
        // navigate(from, { replace: true })
      }
    } catch (err) {
      setErrorMsg(handleAPIError(err))
      errorMsgRef.current.focus()
    }
  }
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 5,
        margin: 5,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" align="center" color="initial" component={RouterLink} to="/manage-user/add">
          {userId === undefined ? 'Add User' : 'Edit User'}
        </Typography>
        <Button variant="text" size="large" onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon /> Go Back
        </Button>
      </Stack>

      <Form
        onSubmit={handleSubmit(onSubmit)}
        defaultValues={defaultValues}
        register={register}
        // errors={formState.errors}
      >
        <>
          {formAction !== 'ADD' && (
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="baseline" sx={{ pb: 2 }}>
              {defaultValues.createdAt && (
                <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2">ID:</Typography>
                  <Typography variant="body2">{defaultValues.id}</Typography>
                </Stack>
              )}
              {defaultValues.createdAt && (
                <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2">Registered On:</Typography>
                  <Typography variant="body2">{fDate(defaultValues.createdAt)}</Typography>
                </Stack>
              )}
              {defaultValues.updatedAt && (
                <Stack direction="row" spacing={2}>
                  <Typography variant="subtitle2">Updated On:</Typography>
                  <Typography variant="body2">{fDate(defaultValues.updatedAt)}</Typography>
                </Stack>
              )}
              <FormCheckbox
                name="isActive"
                label="Is Active"
                control={control}
                errors={formState.errors}
                defaultValue={defaultValues.isActive}
              />
            </Stack>
          )}
          <Stack direction="row" alignItems="baseline" spacing={2} sx={{ pb: 2 }}>
            <FormInput
              fullWidth
              name="firstName"
              placeholder="First Name"
              rules={{
                required: {
                  value: true,
                  message: 'This is required field..',
                },
              }}
              control={control}
              errors={formState.errors}
              autoFocus
            />
            <FormInput
              fullWidth
              name="middleName"
              placeholder="Middle Name"
              control={control}
              errors={formState.errors}
            />
            <FormInput fullWidth name="lastName" placeholder="Last Name" control={control} errors={formState.errors} />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="baseline" sx={{ pb: 2 }}>
            <FormInput
              fullWidth
              name="email"
              placeholder="E-Mail"
              control={control}
              errors={formState.errors}
              disabled={formAction === 'UPDATE'}
            />
            <FormInput fullWidth name="mobile" placeholder="Mobile" control={control} errors={formState.errors} />
          </Stack>
          <Stack direction="row" spacing={2} alignItems="baseline" sx={{ pb: 2 }}>
            <FormFileUpload
              name="avatar"
              control={control}
              multiple={false}
              defaultImage={defaultValues.avatar}
              baseURL={AVATARS_BASE_URL}
              modelId={defaultValues.userId}
              deleteCB={deleteImage}
            />
          </Stack>

          {errorMsg !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2} ref={errorMsgRef}>
              <Alert aria-live="assertive" severity="error">
                {errorMsg}
              </Alert>
            </Stack>
          )}
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={formState.isSubmitting}>
            Submit
          </LoadingButton>
        </>
      </Form>
    </Paper>
  )
}

export default EditUser
