import { Navigate, useRoutes } from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard'
import LogoOnlyLayout from './layouts/LogoOnlyLayout'
//
import Blog from './pages/Blog'
// import User from './pages/User'
import Login from './pages/Login'
import NotFound from './pages/Page404'
// import Register from './pages/Register'
// import Products from './pages/Products'
import DashboardApp from './pages/DashboardApp'
import BlogDetails from './pages/BlogDetails'
import PersistLogin from './components/PersistLogin'
import RequireAuth from './components/RequireAuth'
import ManageNEWS from './pages/ManageNEWS'
import Unauthorized from './pages/Unauthorized'
import EditNews from './pages/EditNews'
import ManageUsers from './pages/ManageUsers'
import EditUser from './pages/EditUser'
import ManageUserRoles from './components/ManageUserRoles'
import ComingSoon from './pages/ComingSoon'
import PrivacyPolicy from './pages/privacy-policy'
import TermsOfService from './pages/terms-of-service'

export default function Router() {
  const ROLES = {
    ADMIN: 'ADMIN',
    AUTHOR: 'AUTHOR',
    READER: 'READER',
    ARTICLE_APPROVER: 'ARTICLE_APPROVER',
  }
  return useRoutes([
    {
      path: '/',
      element: <PersistLogin />,
      children: [
        {
          element: <RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.AUTHOR]} />,
          children: [
            {
              element: <DashboardLayout />,
              children: [
                { path: 'dashboard', element: <DashboardApp /> },
                {
                  path: 'manage-news',
                  children: [
                    { path: '/manage-news', element: <ManageNEWS /> },
                    { path: '/manage-news/add', element: <EditNews /> },
                    { path: '/manage-news/edit/:articleSlug', element: <EditNews /> },
                  ],
                },
                {
                  path: 'manage-user',
                  children: [
                    { path: '/manage-user', element: <ManageUsers /> },
                    { path: '/manage-user/manage-user-roles', element: <ManageUserRoles /> },
                    { path: '/manage-user/add', element: <EditUser /> },
                    { path: '/manage-user/edit/:userId', element: <EditUser /> },
                  ],
                },
                {
                  path: 'manage-tags',
                  children: [
                    { path: '/manage-tags', element: <ComingSoon /> },
                    // { path: '/manage-news/add', element: <EditRole /> },
                    // { path: '/manage-news/edit/:articleSlug', element: <EditRole /> }
                  ],
                },
                {
                  path: 'manage-roles',
                  children: [
                    { path: '/manage-roles', element: <ComingSoon /> },
                    // { path: '/manage-news/add', element: <EditRole /> },
                    // { path: '/manage-news/edit/:articleSlug', element: <EditRole /> }
                  ],
                },
                {
                  path: 'manage-categories',
                  children: [
                    { path: '/manage-categories', element: <ComingSoon /> },
                    // { path: '/manage-news/add', element: <EditRole /> },
                    // { path: '/manage-news/edit/:articleSlug', element: <EditRole /> }
                  ],
                },
              ],
            },
          ],
        },
        {
          element: <DashboardLayout />,
          children: [
            { path: '/', element: <Blog /> },
            { path: 'news/:slug', element: <BlogDetails /> },
            { path: 'news', element: <Blog /> },
            { path: 'unauthorized', element: <Unauthorized /> },
            { path: '404', element: <NotFound /> },
            { path: 'privacy-policy', element: <PrivacyPolicy /> },
            { path: 'terms-of-service', element: <TermsOfService /> },
            // { path: 'user', element: <User /> },
            // { path: 'dashboard', element: <DashboardApp /> },
            { path: '*', element: <Navigate to="/404" /> },
          ],
        },
        {
          element: <LogoOnlyLayout />,
          children: [
            { path: 'login', element: <Login /> },
            // { path: 'register', element: <Register /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
