/* eslint-disable react/no-danger */
import { Helmet } from 'react-helmet-async'
import Router from './routes'
import ThemeProvider from './theme'
import ScrollToTop from './components/ScrollToTop'
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart'
import { AuthProvider } from './context/AuthProvider'

export default function App() {
  const websiteSchema = {
    '@context': 'http://schema.org/',
    '@type': 'WebSite',
    url: 'https://www.mediameetra.com',
  }

  return (
    <ThemeProvider>
      <Helmet>
        {/* <!-- favicon icon --> */}
        <link rel="icon" href="/favicon/favicon.png" />
        <link rel="apple-touch-icon" href="/favicon/apple-touch-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-touch-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-touch-icon-114x114.png" />
        <link rel="canonical" href="https://www.mediameetra.com" />

        {/* SEO */}
        <meta charSet="utf-8" />
        <script type="application/ld+json">{JSON.stringify(websiteSchema)}</script>
        <meta name="description" content="Your Friend for World NEWS!" />
        <meta name="keywords" content="NEWS, Balotra NEWS, World NEWS, India, India NEWS" />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="10 days" />
        <meta name="author" content="https://www.mediameetra.com" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:site_name" content="mediameetra.com" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mediameetra.com" />
        <meta property="og:title" content="Media Meetra | NEWS Portal" />
        <meta property="og:description" content="Your only destination for all News" />
        <meta property="og:image" content="https://www.mediameetra.com/images/og-image.png" />
        <meta property="og:image:secure_url" content="https://www.mediameetra.com/images/og-image.png" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1202" />
        <meta property="og:image:height" content="635" />
        <meta property="og:image:alt" content="Media Meetra" />

        <meta property="business:contact_data:street_address" content="Balotra" />
        <meta property="business:contact_data:locality" content="Balotra" />
        <meta property="business:contact_data:region" content="Rajasthan" />
        <meta property="business:contact_data:postal_code" content="344022" />
        <meta property="business:contact_data:country_name" content="India" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.mediameetra.com" />
        <meta property="twitter:title" content="Media Meetra" />
        <meta property="twitter:description" content="Media Meetra NEWS portal." />
        <meta property="twitter:image" content="https://www.mediameetra.com/images/og-image.png" />
      </Helmet>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <AuthProvider>
        <Router />
      </AuthProvider>
    </ThemeProvider>
  )
}
