/* eslint-disable no-unneeded-ternary */
import { Controller } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

const FormInput = ({
  name,
  rules,
  defaultValue = '',
  placeholder,
  control,
  errors = {},
  // helperText = "",
  // error = false,
  // helperText,
  // color = "lightBlue",
  // size = "regular",
  // outline = false,
}) => {
  // const error = errors?.[name]?.message === ""
  const helperText = errors?.[name]?.message
  const error = helperText !== undefined
  // console.log(`Error: ${error} and message: ${helperText}`)

  return (
    <Controller
      control={control}
      rules={rules}
      render={({ field }) => (
        <TextField
          error={error}
          helperText={helperText}
          // error={helperText === undefined ? false : true}
          // helperText={helperText}
          margin="normal"
          fullWidth
          id={name}
          label={placeholder}
          placeholder={placeholder}
          autoComplete=""
          {...field}
        />
      )}
      name={name}
      defaultValue={`${defaultValue !== null ? defaultValue : ''}`}
    />
  )
}

FormInput.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.any,
  helperText: PropTypes.string,
  errors: PropTypes.object,
  // error: PropTypes.bool
  // color: PropTypes.string,
  // size: PropTypes.string,
  // outline: PropTypes.bool,
}

export default FormInput
