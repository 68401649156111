import { Grid, Container, Typography } from '@mui/material'
import Page from '../components/Page'
import { NAME } from '../constants/AppConfig'

export default function PrivacyPolicy() {
  return (
    <Page title={NAME}>
      <Container>
        <Grid container spacing={3}>
          <Typography variant="body2">
            <ul>
              <li>We use Google Analytics to collect the visitors data.</li>
              <li>We are not collecting any data if you are not logged in.</li>
              <li>We are collecting your email and google public profile in case if you login with Google.</li>
            </ul>
          </Typography>
        </Grid>
      </Container>
    </Page>
  )
}
