import useAuth from './useAuth'
import axios from '../utils/axiosConfig'

const useRefreshToken = () => {
  const { setAuth } = useAuth()

  const refresh = async () => {
    // console.log("refresh: auth: ", auth.accessToken)
    axios.defaults.withCredentials = true
    const response = await axios.get('/users/refreshAccessToken', { withCredentials: true })

    // prev is the previous state value which auth variable is holding
    /* setAuth(prev => {
            console.log(`RefreshHook, prev state: ${JSON.stringify(prev)}`)
            console.log(`AccessToken: ${response.data.accessToken}`)
            return { ...prev, accessToken: response.data.accessToken }
        })
        return response.data.accessToken */
    setAuth({ ...response?.data, isLoggedIn: true })
    return response.data
  }

  return refresh
}

export default useRefreshToken
