/* eslint-disable react-hooks/exhaustive-deps */
import { filter } from 'lodash'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// material
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  Container,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
// components
import { sentenceCase } from 'change-case'
import { AVATARS_BASE_URL } from '../constants/AppConfig'
import Iconify from '../components/Iconify'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import SearchNotFound from '../components/SearchNotFound'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user'
import { fDate } from '../utils/formatTime'
import UserStatusAction from '../components/actions/UserStatusAction'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(users, comparator, query) {
  const stabilizedThis = users.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    // return filter(users, (role) => role.fullName.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    return filter(users, (role) => role.createdAt)
  }
  return stabilizedThis.map((el) => el[0])
}

const TABLE_HEAD = [
  { id: 'avatar', label: 'Avatar', alignRight: false },
  { id: 'fullName', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'roles', label: 'Roles', alignRight: false },
  { id: 'createdAt', label: 'Joined On', alignRight: false },
  { id: 'updatedAt', label: 'Updated On', alignRight: false },
  { id: 'isActive', label: 'Active?', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
]

export default function ManageUsers() {
  const axiosPrivate = useAxiosPrivate()
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('fullName')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [users, setUsers] = useState([])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    axiosPrivate.get('/users').then((data) => {
      console.log(data)
      setUsers(data.data)
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName)

  const isUserNotFound = filteredUsers.length === 0

  return (
    <Page name="Manage Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          <ButtonGroup variant="contained">
            <Button
              variant="contained"
              component={RouterLink}
              to="/manage-user/add"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add User
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/manage-user/manage-user-roles"
              startIcon={<Iconify icon="carbon:user-role" />}
            >
              User Roles
            </Button>
          </ButtonGroup>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, padding: 2 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { userId, firstName, middleName, lastName, email, isActive, createdAt, updatedAt, roles } =
                      row
                    let { avatar } = row
                    avatar =
                      // eslint-disable-next-line no-nested-ternary
                      avatar !== undefined || avatar !== null || avatar !== ''
                        ? avatar.includes('https:')
                          ? avatar
                          : `${AVATARS_BASE_URL}/${avatar}`
                        : ''

                    const fullName = [firstName, middleName, lastName].filter(Boolean).join(' ')
                    return (
                      <TableRow hover key={userId} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={fullName} src={`${AVATARS_BASE_URL}/${avatar}`} />
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          <Typography
                            to={`/manage-user/edit/${userId}`}
                            color="inherit"
                            variant="body2"
                            component={RouterLink}
                          >
                            {sentenceCase(fullName)}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{roles?.map((el) => sentenceCase(el.role.name)).join(', ')}</TableCell>
                        <TableCell align="left">{fDate(createdAt)}</TableCell>
                        <TableCell align="left">{fDate(updatedAt)}</TableCell>
                        <TableCell align="left">
                          <UserStatusAction isPositiveAction={isActive} userId={userId} />
                        </TableCell>
                        <TableCell align="left">
                          <IconButton aria-label="edit" component={RouterLink} to={`/manage-user/edit/${userId}`}>
                            <EditRoundedIcon color="action" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  )
}
