import PropTypes from 'prop-types'
import { createContext, useState } from 'react'
// import { useLocation, useNavigate } from "react-router-dom"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({})
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || true)

  /* const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/'
    const logout = async () => {
        // if used in more components, this should be in context axios to /logout endpoint 
        setAuth({ isLoggedIn: false })
        navigate(from, { replace: true })
    } */
  return <AuthContext.Provider value={{ auth, setAuth, persist, setPersist }}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.element,
}

export default AuthContext
