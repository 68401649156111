import { useNavigate } from "react-router-dom"
import axios from "../utils/axiosConfig"
import useAuth from "./useAuth"

const useLogout = () => {
  const { setAuth } = useAuth()
  const navigate = useNavigate()

  const logout = async () => {
    setAuth({})
    localStorage.removeItem('persist')
    try {
      await axios.get('/users/logout', {
        withCredentials: true
      })
      navigate('/')
    } catch (error) {
      console.log(error)
    }
  }

  return logout
}

export default useLogout