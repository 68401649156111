/* eslint-disable no-unneeded-ternary */
import { IconButton } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Iconify from '../Iconify'

const FormPassword = ({
  name,
  rules,
  defaultValue,
  placeholder,
  control,
  errors = {},
  // helperText = "",
  // error = false,
  // helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  // const error = errors?.[name]?.message === ""
  const helperText = errors?.[name]?.message
  const error = helperText !== undefined
  // console.log(`Error: ${error} and message: ${helperText}`)

  return (
    <Controller
      control={control}
      render={({ field }) => (
        <TextField
          error={error}
          helperText={helperText}
          // error={helperText === undefined ? false : true}
          // helperText={helperText}
          margin="normal"
          fullWidth
          id={name}
          label={placeholder}
          placeholder={placeholder}
          autoComplete=""
          {...field}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

FormPassword.propTypes = {
  // You can declare that a prop is a specific JS primitive
  // By default, these are all optional
  name: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  outline: PropTypes.bool,
  control: PropTypes.any,
  helperText: PropTypes.string,
  // error: PropTypes.bool
  errors: PropTypes.object,
}

export default FormPassword
