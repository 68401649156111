/* eslint-disable no-nested-ternary */
import { useLocation, Navigate, Outlet } from "react-router-dom"
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth()
  const location = useLocation()
  const roles = auth?.roles?.map(el => el.role.name)
  // console.log(`Roles found: ${JSON.stringify(roles)}`)
  return (
    roles?.find(role => allowedRoles?.includes(role))
      ? <Outlet />
      : auth?.accessToken
        ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  )
}

RequireAuth.propTypes = {
  allowedRoles: PropTypes.array,
}

export default RequireAuth