/* eslint-disable no-console */
import axios from 'axios'
import { API_BASE_URL } from '../constants/AppConfig'

export default axios.create({
  baseURL: API_BASE_URL,
  // withCredentials: true
})

export const axiosPrivate = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
})

/* export const axiosPrivateFile = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: true
}) */

export const axiosInstance = (accessToken, contentType = 'application/json') => {
  console.log(`** accessToken: ${accessToken}`)
  const authorizationToken = accessToken !== undefined ? `Bearer ${accessToken}` : ''
  // const withCredentials = accessToken !== undefined ? true : undefined

  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Content-Type': contentType,
      Authorization: authorizationToken,
    },
    withCredentials: true,
  })
}
