import { Grid, Container, Typography } from '@mui/material'
import Page from '../components/Page'
import { NAME } from '../constants/AppConfig'

export default function TermsOfService() {
  return (
    <Page title={NAME}>
      <Container>
        <Grid container spacing={3}>
          <Typography variant="body2">
            <ul>
              <li>Our Terms of Services are simple, please don't attempts anything illegal actions on the site.</li>
            </ul>
          </Typography>
        </Grid>
      </Container>
    </Page>
  )
}
