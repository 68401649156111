import React from 'react'
import Typography from '@mui/material/Typography'

const ComingSoon = () => {
  console.log('coming soon page')
  return (
    <Typography varient="h1" component="h1">Coming soon..</Typography>
  )
}

export default ComingSoon