import { useEffect, useState } from 'react'
import { Grid, Container } from '@mui/material'
// material
// components
import Stack from '@mui/material/Stack'
import Pagination from '@mui/material/Pagination'
import Page from '../components/Page'
// import Iconify from '../components/Iconify'
// import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog'
import { BlogPostCard } from '../sections/@dashboard/blog'
// mock
// import POSTS from '../_mock/blog'
// import posts from '../_mock/blog'
import { NAME } from '../constants/AppConfig'
import axios from '../utils/axiosConfig'
// ----------------------------------------------------------------------

/* const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
] */

// ----------------------------------------------------------------------

export default function Blog() {
  const [posts, setPosts] = useState([])
  const [ipp, setIpp] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  // const [totalArticles, setTotalArticles] = useState(0)
  const [pageCount, setPageCount] = useState(1)

  const handlePagination = (e, page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    axios.get(`/articles/all-articles-count?isActive=true&isPublished=true&isVerified=true`).then((data) => {
      // setTotalArticles(data.data.data)
      setPageCount(Math.ceil(data.data.data / ipp))
      setIpp(11)
    })

    axios.get(`/articles?ipp=${ipp}&page=${currentPage}&sortBy=createdAt&order=desc`).then((data) => {
      setPosts(data.data.data)
    })
  }, [ipp, currentPage])

  return (
    <Page title={NAME}>
      <Container>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blog
          </Typography>
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button>
        </Stack> */}

        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack> */}

        <Grid container spacing={3}>
          {posts.length > 0 &&
            posts.map((post, index) => <BlogPostCard key={post.articleId} post={post} index={index} />)}
        </Grid>
      </Container>
      {pageCount > 1 && (
        <Container p={3}>
          <Stack p={2}>
            <Pagination
              count={pageCount}
              variant="outlined"
              color="primary"
              page={currentPage}
              onChange={handlePagination}
            />
          </Stack>
        </Container>
      )}
    </Page>
  )
}
