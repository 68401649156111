/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
// @mui
import { styled } from '@mui/material/styles'
import { Card, Container, Divider, Typography } from '@mui/material'
// hooks
import useResponsive from '../hooks/useResponsive'
// components
import Page from '../components/Page'
import Logo from '../components/Logo'
// sections
import { LoginPage } from '../sections/auth/LoginPage'
import AuthSocial from '../sections/auth/AuthSocial'
import axios from '../utils/axiosConfig'
// ----------------------------------------------------------------------
import useAuth from '../hooks/useAuth'
// import handleAPIError from '../utils/handleAPIError'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}))

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function Login() {
  // const smUp = useResponsive('up', 'sm')
  const mdUp = useResponsive('up', 'md')

  const { setAuth } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'
  const [isToken, setIsToken] = useState(false)
  useEffect(() => {
    const token = searchParams.get('token')
    console.log(`token: ${token}`)
    const loginWithToken = async () => {
      if (token !== null) {
        setIsToken(true)
        try {
          const response = await axios.post('users/login/withtoken', { token })
          setAuth({ ...response?.data, isLoggedIn: true })
          navigate(from, { replace: true })
        } catch (err) {
          setIsToken(false)
          navigate('/login', { replace: false })
        }
      } else {
        setIsToken(false)
      }
    }
    loginWithToken()
  }, [])
  return (
    <Page title="Login">
      {!isToken ? (
        <RootStyle>
          <HeaderStyle>
            <Logo />

            {/* {smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                Don’t have an account? {''}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
          </HeaderStyle>

          {mdUp && (
            <SectionStyle>
              <Typography variant="h3" sx={{ px: 5, mt: 15, mb: 0 }}>
                Welcome to Media Meetra!
              </Typography>
              <img src="/static/illustrations/illustration_login.png" alt="login" />
            </SectionStyle>
          )}

          <Container maxWidth="sm">
            <ContentStyle>
              <Typography variant="h4" gutterBottom>
                Join MediaMeetra!
              </Typography>

              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                Please Register or Login with Below options:
              </Typography>

              <AuthSocial />

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  OR
                </Typography>
              </Divider>
              <LoginPage />

              {/* {!smUp && (
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Don't have an account?{' '}
                  <Link variant="subtitle2" component={RouterLink} to="/register">
                    Get started
                  </Link>
                </Typography>
              )} */}
            </ContentStyle>
          </Container>
        </RootStyle>
      ) : (
        <p>All set, logging you in..</p>
      )}
    </Page>
  )
}
